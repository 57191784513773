import React, { useEffect, useState } from "react";
import { LoginError } from "../components/TWComponents/LoginError";

import Layout from "../layouts/en";
import SEO from "../components/seo";

const LoginErrorPage = (props) => {
  const [description, setDescription] = useState(null);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const queryParams = new URLSearchParams(window.location.search);
      setDescription(queryParams.get("error_description"));
    }
  }, []);

  return (
    <Layout location={props.location} showBookNowButton={false}>
      <SEO
        title="Login Failed"
        keyword="Login Failed"
        decription="Login Failed"
      />
      <LoginError
        title="Gagal masuk akun"
        description={description || "No error description available."}
        subDescription="Terjadi kendala saat memproses permintaan masuk akun Anda. Mohon coba kembali, atau hubungi tim kami jika masalah ini terus berlanjut."
        showErrorDetails="Tunjukkan detail error"
        hideErrorDetails="Sembunyikan detail error"
        needAssistance="Butuh bantuan?"
        contactSupport="Hubungi tim kami"
      />
    </Layout>
  );
};

export default LoginErrorPage;
